import React, { useState } from 'react';
import './App.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import preval from 'preval.macro'

import Button from 'react-bootstrap/Button';

const build_ts = preval`module.exports = new Date().toLocaleString()`

console.log(`Environment: ${process.env.REACT_APP_terraform_env}`)
console.log(`Deployed: ${process.env.REACT_APP_deployment_timestamp}`)
console.log(`Build Date: ${build_ts}.`)

function App() {

  const [submitting, set_submitting] = useState(false);
  const [s3_path_pdf, set_s3_path_pdf] = useState("");
  const [s3_path_png, set_s3_path_png] = useState("lorem-ipsum-haas.png");
  const [interpolation_string, set_interpolation_string] = useState("Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.");
  const [textarea_default_value, set_textarea_default_value] = useState(true);
  const [png_outdated, set_png_opaque] = useState(false)

  const s3_bucket_id = process.env.REACT_APP_s3_bucket_id
  const api_gw_invoke_url = process.env.REACT_APP_api_gw_invoke_url

  const handleSubmit = event => {
    event.preventDefault();

    const sanitised_string = event.target[1].value;

    set_submitting(true);
    set_s3_path_pdf("")

    const request_body = {
      interpolation_string: sanitised_string,
      title: "HAAS",
      s3_path: `s3://${s3_bucket_id}/haas`
    }

    console.log(request_body)

    // request pdf
    fetch(`${api_gw_invoke_url}latest`, {
      method: 'POST',
      body: JSON.stringify({
        ...request_body,
        requested_pdf: "true"
      }),
      headers: { 'Content-Type': 'application/json' }
    }).then(res => res.text().then(data => {
      const parsed_data = JSON.parse(data);
      set_s3_path_pdf(parsed_data.body_json.presigned_url_pdf);
      set_submitting(false);
    }))
      .then(data => {
        return data
      })
      .catch(err => console.error("Error:", err));


    // request png
    fetch(`${api_gw_invoke_url}latest`, {
      method: 'POST',
      body: JSON.stringify({
        ...request_body,
        requested_png: "true"
      }),
      headers: { 'Content-Type': 'application/json' }
    }).then(res => res.text().then(data => {
      const parsed_data = JSON.parse(data);
      set_s3_path_png(parsed_data.body_json.presigned_url_png);
      set_png_opaque(false);
    }))
      .then(data => {
        return data
      })
      .catch(err => console.error("Error:", err));
  }

  function resetTextArea() {
    if (textarea_default_value) {
      set_interpolation_string("")
      set_textarea_default_value(false)
    }
  }

  const handleUserInput = (e) => {
    // forbid latex escape characters (https://tex.stackexchange.com/a/34586):
    // & % $ # _ { } ~ ^ \
    // and also * "
    const whitelist = /[^\w.,:;'<>\-[\]+=?!@`()| ]/g
    const sanitised_string = e.target.value.replaceAll(whitelist, '').replaceAll('_', '')

    set_interpolation_string(sanitised_string);
    set_png_opaque(true)
  };

  return (

    <Container className="center">
      <link
        rel="stylesheet"
        href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.0-beta1/dist/css/bootstrap.min.css"
        integrity="sha384-0evHe/X+R7YkIZDRvuzKMRqM+OrBnVFBL6DOitfPri4tjfHxaWutUpFmBp4vmVor"
        crossOrigin="anonymous"
      />
      <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.2.2/dist/js/bootstrap.bundle.min.js" integrity="sha384-OERcA2EqjJCMA+/3y+gxIOqMEjwtxJY7qPCqsdltbNJuaOe923+mo//f6V8Qbsw3" crossOrigin="anonymous"></script>
      <h2>♥AAS</h2>
      <form onSubmit={handleSubmit}>
        <fieldset>
          <label>
            <p>Please enter the text you would like to be rendered heart shaped</p>
          </label>
          <Row>
            <Col>
              <textarea className={
                (textarea_default_value ? "textarea-default-value-formatting " : "")
              }
                name="INTERPOLATION_TEXT"
                rows="13"
                style={{
                  minWidth: "70%",
                  resize: "none",
                }}
                maxLength="2500"
                onClick={resetTextArea}
                value={interpolation_string}
                onChange={handleUserInput} />
            </Col>
            <Col>
              <img className="img-fluid" alt="haas" src={s3_path_png} style={{ maxWidth: "100%", opacity: png_outdated ? "50%" : "100%" }} />
            </Col>
          </Row>
          <br />
        </fieldset>
        <br />
        <Button type="submit" disabled={interpolation_string === "" && true}>RENDER</Button>
        <br />
        <br />
        {submitting && <div>Rendering text...</div>}
        {s3_path_pdf !== "" && <a target="_blank" rel="noreferrer" download href={s3_path_pdf} >Download (PDF)</a>}
      </form>
      <br />
      <br />
      <br />
      <Container>
        <Row>
          <h5>powered by</h5>
        </Row>
        <br />
        <br />
        <Row className="justify-content-center">
          <Col md={8} lg={6}>
            <Row>
              <Col>
                <img className="img-fluid" alt="powered by terraform" src="logo-terraform.png" style={{ maxWidth: "70%" }} />
              </Col>
              <Col>
                <img className="img-fluid" alt="powered by docker" src="logo-docker.png" style={{ maxWidth: "70%" }} />
              </Col>
              <Col>
                <img className="img-fluid" alt="powered by lambda" src="logo-lambda.png" style={{ maxWidth: "70%" }} />
              </Col>
              <Col>
                <img className="img-fluid" alt="powered by react" src="logo-react.png" style={{ maxWidth: "70%" }} />
              </Col>
            </Row>
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            <img className="img-fluid me-2" alt="gitlab" src="logo-gitlab.png" style={{ maxWidth: "3%" }} />
            <a target="_blank" rel="noreferrer" href="https://gitlab.christianhomberg.me/chr_96er/haas/tree/staging">gitlab.christianhomberg.me/chr_96er/haas</a>
          </Col>
        </Row>
      </Container>
    </Container >
  )
}

export default App;